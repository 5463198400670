<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body>
            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- Email Address-->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('EMAIL_ADDRESS')"
                      label-for="id-email-address"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('EMAIL_ADDRESS')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-email-address"
                          v-model="user.email"
                          maxlength="60"
                          :placeholder="$t('EMAIL_ADDRESS')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Role -->
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('ROLE')"
                      label-for="id-role"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('ROLE')"
                        rules="required"
                      >
                        <v-select
                          id="id-role"
                          v-model="role"
                          :placeholder="$t('ROLE')"
                          label="name"
                          class="flex-grow-1"
                          :clearable="false"
                          :options="roles"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- First Name -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('FIRST_NAME')"
                      label-for="id-firstname"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('FIRST_NAME')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-firstname"
                          v-model="user.firstName"
                          maxlength="45"
                          :placeholder="$t('FIRST_NAME')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Last Name -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('LAST_NAME')"
                      label-for="id-lastname"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('LAST_NAME')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-lastname"
                          v-model="user.lastName"
                          maxlength="45"
                          :placeholder="$t('LAST_NAME')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Business Unit-->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('BUSINESS_UNIT')"
                      label-for="id-business-unit"
                    >
                      <b-form-input
                        id="id-business-unit"
                        v-model="user.businessUnit"
                        maxlength="50"
                        :placeholder="$t('BUSINESS_UNIT')"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Language -->
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('LANGUAGE')"
                      label-for="id-language"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('LANGUAGE')"
                        rules="required"
                      >
                        <v-select
                          id="id-language"
                          v-model="language"
                          :placeholder="$t('LANGUAGE')"
                          label="name"
                          class="flex-grow-1"
                          :clearable="false"
                          :options="LANGUAGES"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Company -->
                  <b-col cols="6">
                    <b-form-group
                      :label="$t('COMPANY')"
                      label-for="id-company"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('COMPANY')"
                        rules="required"
                      >
                        <v-select
                          id="id-company"
                          v-model="company"
                          :placeholder="$t('COMPANY')"
                          label="name"
                          class="flex-grow-1"
                          :clearable="false"
                          :options="allCompanies"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Phone Number -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('PHONE_NUMBER')"
                      label-for="id-phonenumber"
                    >

                      <VuePhoneNumberInput
                        v-model="user.phoneNumber"
                        :default-country-code="getIsoCountryCode(user.countryCode+user.phoneNumber)"
                        @update="handlePhoneNumberInput"
                      />

                    </b-form-group>
                  </b-col>
                  <!-- Send Weekly report -->
                  <b-col cols="6">
                    <b-form-group>
                      <b-form-checkbox
                        id="id-send_weekly_report"
                        v-model="user.isSendDailyEmail"
                        name="send_weekly_report"
                      >
                        {{ $t('SEND_WEEKLY_REPORT') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <!-- Preferred notifications -->
                  <b-col cols="6">
                    <b-form-group :label="$t('NOTIFICATION_METHOD')">
                      <b-form-radio-group
                        id="notification-method-radio-group"
                        v-model="selectedPreference"
                        name="contactPreference"
                        :options="[
                          { text: $t('EMAIL'), value: 'email' },
                          { text: $t('SMS'), value: 'sms' }
                        ]"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Submit and Reset -->
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ user.id ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      {{ $t('BUTTON.RESET') }}
                    </b-button>
                  </b-col>

                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadioGroup,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
// eslint-disable-next-line import/no-extraneous-dependencies
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions } from 'vuex'
import VuePhoneNumberInput from 'vue-phone-number-input'
// eslint-disable-next-line import/no-extraneous-dependencies
import parsePhoneNumberFromString from 'libphonenumber-js' // Import the utility library
import constants from '@/constants/static.json'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    BFormRadioGroup,
    // eslint-disable-next-line vue/no-unused-components
    parsePhoneNumberFromString,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: {
        id: '',
        firstName: '',
        lastName: '',
        nick: '',
        companyId: '',
        email: '',
        phoneNumber: '',
        businessUnit: '',
        countryCode: constants.DEFULT_COUNTRY_CODE.DIALING_CODE,
        userRoleId: 0,
        aboutMe: '',
        isSendDailyEmail: false,
        isSmsPreferred: false,
        isEmailPreferred: false,
        language: '',
      },
      phonePayload: {
        countryCode: '',
        isValid: false,
        phoneNumber: '',
        countryCallingCode: constants.DEFULT_COUNTRY_CODE.ISO_COUNTRY_CODE,
        formattedNumber: '',
        nationalNumber: '',
        formatInternational: '',
        formatNational: '',
        uri: '',
        e164: '',
      },
      required,
      company: '',
      selectedPreference: false,
      allCompanies: [],
      language: '',
      roles: [],
      role: '',
      userData: useJwt.getUser(),
      userRole: constants.USER_ROLE,
    }
  },
  watch: {
    selectedPreference(newValue) {
      this.user.isEmailPreferred = newValue === 'email'
      this.user.isSmsPreferred = newValue === 'sms'
    },
  },
  async created() {
    if (router.currentRoute.params.id) {
      await this.fetchUser(router.currentRoute.params.id)
      await this.setLanguage()
    }
    await this.fetchCompany()
    await this.fetchRoles()
    this.initializePreference()
  },
  setup() {
    const { LANGUAGES } = constants
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
      LANGUAGES,
    }
  },
  methods: {
    ...mapActions('company', ['fetchAllCompanies']),
    ...mapActions('user', ['createUser',
      'updateUser',
      'fetchUserByID',
      'fetchUserRoles',
      'fetchUserByEmail']),
    async onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.user.companyId = this.company.id
          this.user.userRoleId = this.role.id
          this.user.language = this.language.id
          if (this.phonePayload.nationalNumber) {
            this.user.phonenumber = this.phonePayload.nationalNumber
            this.user.countryCode = `+${this.phonePayload.countryCallingCode}`
          }
          if (this.user.id) {
            this.updateUser(this.user).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.USER_UPDATED'))
                this.$router.push({ name: 'user-list' })
              }
            }).catch(error => {
              if (error.response && error.response.status === 400) {
                this.errorMessage(error.response.data.message)
              } else {
                this.showErrorMessage()
              }
            })
          } else {
            this.fetchUserByEmail(this.user.email).then(response => {
              if (response) {
                if (response.emailExists) {
                  this.errorMessage(this.$i18n.t('ALREADY_EXIST_EMAIL'))
                } else {
                  delete this.user.id
                  this.createUser(this.user).then(result => {
                    if (result) {
                      this.successMessage(this.$i18n.t('MESSAGE.USER_CREATED'))
                      this.$router.push({ name: 'user-list' })
                    }
                  }).catch(() => {
                    this.showErrorMessage()
                  })
                }
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        }
      })
    },
    initializePreference() {
      // eslint-disable-next-line no-nested-ternary
      this.selectedPreference = this.user.isEmailPreferred
        ? 'email'
        : this.user.isSmsPreferred
          ? 'sms'
          : ''
    },
    getIsoCountryCode(mobile) {
      let isoCountryCode = constants.DEFULT_COUNTRY_CODE.ISO_COUNTRY_CODE
      if (mobile) {
        const phoneNumber = parsePhoneNumberFromString(mobile, 'ZZ')
        // Extract the ISO country code
        isoCountryCode = phoneNumber ? phoneNumber.country : constants.DEFULT_COUNTRY_CODE.ISO_COUNTRY_CODE
      }
      return isoCountryCode
    },
    handlePhoneNumberInput(payload) {
      // Access the detailed payload from the @input event
      this.phonePayload = payload
    },
    async fetchCompany() {
      try {
        await this.fetchAllCompanies().then(response => {
          if (response) {
            this.allCompanies = response.data
            if (this.user.companyId) {
              this.company = this.allCompanies.find(i => i.id === this.user.companyId)
            } else if (this.allCompanies.length === 1) {
              // eslint-disable-next-line prefer-destructuring
              this.company = this.allCompanies[0]
            }
          }
        })
      } catch (error) {
        this.showErrorMessage()
      }
    },
    async fetchRoles() {
      try {
        const response = await this.fetchUserRoles()
        if (response) {
          this.roles = response.data
          if (this.userData.userRoleId !== this.userRole.ADMIN) {
            this.roles = this.roles.filter(role => role.id !== this.userRole.ADMIN)
          }
          if (this.user.userRoleId) {
            this.role = this.roles.find(role => role.id === this.user.userRoleId)
          }
        }
      } catch (error) {
        this.showErrorMessage()
      }
    },
    async setLanguage() {
      try {
        if (this.user.language) {
          this.language = this.LANGUAGES.find(i => i.id === this.user.language)
        }
      } catch (error) {
        this.showErrorMessage()
      }
    },
    async fetchUser(id) {
      try {
        await this.fetchUserByID(id).then(response => {
          if (response) {
            const { data } = response.data
            this.user = data
          }
        })
      } catch (error) {
        this.showErrorMessage()
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '~vue-phone-number-input/dist/vue-phone-number-input.css';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>

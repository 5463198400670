// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default function mixinAlert() {
  // Use toast
  const toast = useToast()
  const successMessage = message => {
    toast({
      component: ToastificationContent,
      props: {
        title: message,
        icon: 'CheckIcon',
        variant: 'success',
      },
    })
  }
  const warningMessage = message => {
    toast({
      component: ToastificationContent,
      props: {
        title: message,
        icon: 'AlertCircleIcon',
        variant: 'warning',
      },
    })
  }

  const errorMessage = message => {
    toast({
      component: ToastificationContent,
      props: {
        title: message,
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    })
  }

  const showErrorMessage = () => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Oops! Something went wrong while processing your request.',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    })
  }

  return {
    successMessage,
    warningMessage,
    errorMessage,
    showErrorMessage,
  }
}
